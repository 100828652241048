import React from "react"

const PG_PP_INFO_SECTION_CONTENT = {
  us: {
    t1: () => (
      <>
        This page informs you of our policies regarding the collection, use and
        disclosure of Personal Information we receive from users of the Site.
        This Privacy Policy governs our data collection, processing, usage and
        retention practices. It also describes your choices regarding use,
        access, and correction of your personal information. If you do not agree
        with the data practices described in this Privacy Policy, you should not
        use our Site and/or our associated website application (the “App”)
        and/or our Google Chrome extensions. This website{" "}
        <a className="PgTOU__link" href="https://amzsc.amzgb.com">https://amzsc.amzgb.com</a> (the “Site”) is
        operated by AMZScout Corp., a Pennsylvania corporation (“AMZScout,”
        “we,” and/or “us”).
      </>
    ),
    t2: () => (
      <>
        We periodically update this Privacy Policy. We will post any privacy
        policy changes on this page and, if the changes are significant, we will
        provide a more prominent notice by sending you an email notification.
        While we will notify you of any material changes to this Privacy Policy,
        we encourage you to review this Privacy Policy periodically.
      </>
    ),
    t3: () => (
      <>
        This Privacy Policy was last updated on <b>December 1, 2020</b> and will
        continue to remain in effect in its present form except with respect to
        any changes to its provisions in the future, which will be in effect
        immediately upon being posted on the Site. We reserve the right to
        update or change our Privacy Policy at any time and you should check
        this Privacy Policy periodically. Your continued use of the Service
        after we post any modifications to the Privacy Policy on this page will
        constitute your acknowledgment of the modifications and your consent to
        abide by and be bound by the modified Privacy Policy. If we make any
        material changes to this Privacy Policy, we will notify you either
        through the email address that you have provided to us, or by placing a
        prominent notice on our Site.
      </>
    ),
    t4: () => (
      <>
        <ul className="all_contacts">
          <li>
            <b>AMZSCOUT CORPORATION</b>
          </li>
          <li>1735 Market Street, Suite 3750</li>
          <li>Philadelphia, Pennsylvania 19103</li>
        </ul>
        <p>
          <b>EMAIL ADDRESS:</b> support@amzsc.amzgb.com
        </p>
        <p>
          <b>LAST UPDATED </b>DECEMBER 1, 2020
        </p>
      </>
    ),
    t5: () => (
      <>
        <h2 className="oldTitle">CALIFORNIA PRIVACY RIGHTS</h2>{" "}
        <p>
          California residents may request information regarding the disclosure
          of their personal information to third parties for direct marketing
          purposes. To make such a request, please contact us at our email
          address or by mail at the physical address listed below.
        </p>{" "}
        <p>
          California law requires that operators of online services disclose how
          they respond to a Do Not Track signal. Some browsers have incorporated
          “Do Not Track” features. Most of these features, when turned on, send
          a signal or preference to the online service that a user visits,
          indicating that the user does not wish to be tracked. At this time we
          do not respond to Do Not Track signals.
        </p>
      </>
    ),
  },
  eu: {
    t1: () => (
      <>
        This page informs you of our policies regarding the collection, use and
        disclosure of Personal Information we receive from users of the Site.
        This Privacy Policy governs our data collection, processing, and usage
        practices. It also describes your choices regarding use, access, and
        correction of your personal information. If you do not agree with the
        data practices described in this Privacy Policy, you should not use our
        Site and/or our associated website application (the “App”) and/or our
        Google Chrome extensions. This website{" "}
        <a className="PgTOU__link" href="https://amzsc.amzgb.com">https://amzsc.amzgb.com</a> (the “Site”) is
        operated by AMZScout Corp., a Pennsylvania corporation and Growthco
        DMCC, a company incorporated in United Arab Emirates, licence number
        DMCC-818075 (“AMZScout”, “we,” and/or “us”).
      </>
    ),
    t2: () => (
      <>
        We periodically update this Privacy Policy. We will post any privacy
        policy changes on this page and, if the changes are significant, we will
        provide a more prominent notice by sending you an email notification.
        While we will notify you of any material changes to this Privacy Policy,
        we encourage you to review this Privacy Policy periodically.
      </>
    ),
    t3: () => (
      <>
        This Privacy Policy was last updated on <b>December 1, 2020</b> and will
        continue to remain in effect in its present form except with respect to
        any changes to its provisions in the future, which will be in effect
        immediately upon being posted on the Site. We reserve the right to
        update or change our Privacy Policy at any time and you should check
        this Privacy Policy periodically. Your continued use of the Service
        after we post any modifications to the Privacy Policy on this page will
        constitute your acknowledgment of the modifications and your consent to
        abide by and be bound by the modified Privacy Policy. If we make any
        material changes to this Privacy Policy, we will notify you either
        through the email address that you have provided to us, or by placing a
        prominent notice on our Site.
      </>
    ),
    t4: () => (
      <>
        <ul className="all_contacts">
          <li>
            <b>AMZScout Corp.</b>
          </li>
          <li>1735 Market Street, Suite 3750</li>
          <li>Philadelphia, Pennsylvania 19103</li>
          <li>
            <b>Growthco DMCC</b>
          </li>
          <li>Unit No: BA402</li>
          <li>DMCC Business Centre</li>
          <li>Level No 1</li>
          <li>Jewellery & Gemplex 3</li>
          <li>Dubai</li>
          <li>United Arab Emirates</li>
        </ul>
        <p>
          <b>EMAIL ADDRESS:</b> support@amzsc.amzgb.com
        </p>
        <p>
          <b>EFFECTIVE AS OF </b>OCTOBER 1ST, 2020
        </p>
      </>
    ),
    t5: () => <></>,
  },
}
export default PG_PP_INFO_SECTION_CONTENT
